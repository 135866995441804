/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";
//@import '../src/theme/variables.scss';

// @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700&display=swap');

$colors: (
  // primary:   ,#0098EC, #488aff,
  primary:    #2196F3,
  my_cyan:    #0098EC,
  secondary:  #32db64,
  danger:     #f53d3d,
  light:      #f4f4f4,
  dark:       #222,
  clrwhite:(
  base: #ffffff,
  contrast: #488aff
    )
);
$grid-breakpoints: (
  xs: 0, // Phone (0px - 575px)
  sm: 576px, // Phablet (576px - 767px)
  md: 768px, // Tablet vertical (768px - 991px)
  lg: 992px // Tablet horizontal, Desktop (992px and above)
);
.segment-md .segment-button.activated, .segment-md .segment-button.segment-activated {
    border-bottom-color: #fdd016 !important;
    border-bottom-style: solid !important;
    border-bottom-width: 3px !important;
}
ion-icon{
    pointer-events: none;
}
.p-err
{
  color: red;
  padding-left: 15px;
  padding-top: 2px;
  padding-bottom: 2px;
  align-content: left !important;
}
.native-input[disabled] {
    opacity: 10 !important;
}
.title{
    border: none;
    color: black;
    font-size: large;
}
// http://ionicframework.com/docs/theming/
// @import "'Roboto', sans-serif";
// @import "noto-sans";

.r-t-option {
    border-radius: 10px;
    border: 1px solid #f4f4f4;
    overflow: hidden;
    width: 93%;
  }

  .addBottomSheet {
    color: #2496f6 !important;
    font-size: 3em !important;
  }

  .pro-cir{
    background-color: #c1c1c1;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    line-height: 12px;
    text-align: center;
    color: #fff;
    font-size: 50px;
    align-items: center;
    justify-content: center;
  }

  .alignIcon{
      margin-top:7px;
  }
  
  .r-t-option.segment-ios .segment-button.activated,
  .r-t-option.segment-ios .segment-button,
  .r-t-option.segment-md .segment-button,
  .r-t-option.segment-md .segment-button.activated {
    border: none !important;
    text-transform: none !important;
  }
  
  .r-t-option.segment-md-light .segment-button.activated,
  .r-t-option.segment-md-light .segment-button.segment-activated,
  .r-t-option.segment-ios-light .segment-button.activated,
  .r-t-option.segment-ios-light .segment-button.segment-activated {
    border-bottom: none !important;
    background-color: #1889e6;
    color: #ffffff;
  }
  
  .r-t-option.segment-md .segment-button,
  .r-t-option.segment-ios .segment-button {
    color: #000000;
  }

//   ::-webkit-scrollbar{
//     display:none;
//     }

.menu_show{
    display:block !important;
}

.alert-md .alert-radio-label {
    overflow: visible;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    text-overflow: ellipsis;
    white-space: normal !important; 
    color: initial;
    padding: 13px 26px;
}

.toolbar-md {
    padding: 4px;
   // min-height: 46px !important;
}
.bar-button-default-md, .bar-button-clear-md-default, .bar-button-md-default
{
    color: white;
}

.toolbar-title-md-small
{
    font-weight: normal !important;
    font-size: 1.5rem;
    padding-top:2px;
}

.intro-body
{
background-color: #2196F3;
color: white;
}

.btn_cmn
{
background-color: white;
padding: 20px;
}
.button-outline-md:hover:not(.disable-hover){
    border-color:#0859e4;
    background-color: white;
}
.fnt_color_white
{
font: 25px ;
font-family:'Roboto', sans-serif !important;
}

.sub_fnt_color_white
{
font: 14px 'Roboto', sans-serif !important;
}

.input_login
{
    background-color: #64B6F7;
     padding: 6px;
    ::placeholder { color: #C1E2FC; font-family:'Roboto', sans-serif !important;font-weight:100;font-size: 20px; }//64B6F7 C1E2FC
}

.intro-body .item-inner
{
    border-bottom: none !important;
}
.img_login
{
    margin-top: 50px;
    height: 100px;
    width: 100px;
    margin-right:11px;
}
.button-cstm {
    background: #ebebeb !important;
    color: #00A2E8 !important;
    font-family: ''Roboto', sans-serif' !important;
    font-weight: 300;
}
.button-lr {
    background: #00A2E8 !important;
    color: white !important;
    font-family: 'Roboto', sans-serif !important;
    font-weight: 300;
}
.button-lr-color {
    background: #00A2E8 !important;
    color: black !important;
    font-family: ''Roboto', sans-serif' !important;
    font-weight: 300;
}
.icon-md-grey {
     color: #9e9e9e !important;//488aff
    
}

.button-cstm-color {
    background:#00A2E8 !important;
    color:  black !important;
    font-family: 'Roboto', sans-serif !important;
    font-weight: 300;
}
.item-note
{
   // color:  black !important;
    font-family: ''Roboto', sans-serif' !important;
}
.item-note .item-second{
    color: #878787 !important;
    font-family: ''Roboto', sans-serif' !important;
    margin-top: 8px !important;
}
.item-note .item-dt{
   
   // color:black !important;
    font-family: ''Roboto', sans-serif' !important;
    font-size: 12px !important;
}
.item-note .item-status{
    font-family: ''Roboto', sans-serif' !important;
    font-size: 12px !important;
    padding: 1px !important;
    max-width: 100% !important;
}

.item-nodata
{
    color:  #404040 !important;
    font-size: 1.1em !important;
    font-weight: 600 !important;
    font-family: ''Roboto', sans-serif' !important;
}
.item-nodata .item-second{
    color: #878787 !important;
    font-size: 1em !important;
    font-weight: 200 !important;
    font-family: ''Roboto', sans-serif' !important;
}

.label_login
{
     color: black;
}
.label_version
{
     color:black;
}
.a-cmn
{
color: black;
text-decoration: underline;
}

.a-lr
{
color: #00A2E8;
text-decoration: underline;
cursor: pointer;
}

.a-cmn1
{
 color: white;
 text-decoration: underline;
 margin-top:7px !important;
}

.datetime-md {
    padding: 11px 7px 7px 8px !important;
}

.col {
    position: initial !important;
}

.flash-icon-img {
    width: 33% !important;
}

.alertCustomCss {
    // text color for basic alerts
    z-index:1000;
    color: white; 
    button.alert-button:nth-child(1){
        color:black;
        outline-color:1px black;
        border-radius:2px;
      }
      button.alert-button:nth-child(2){
        color: white !important;
        background-color: #2196F3 !important;
      }
    .alert-message {
        color: black; // text color for confirm alerts
    }
  }
.swiper-pagination-bullet-active {
    background: white !important;
    opacity: 1 !important;
}

.btn-border-radius{
    border-radius: 2px !important;
   // width:40%;
}

.user_img
{
  border-radius: 50%;
  overflow: hidden;
  width: 60px;
  height: 60px;
  margin-top: 6px !important;
}
.hyperlink-align{
    text-align: center !important;
}
.hyperlink-color
{
color: white;
font-size: initial !important;
}
.label-register{
    margin:13px 8px 5px 0px !important;
}

    .nanofee-italic{
        font-style: italic;
    }

  @media only screen and (max-width: 400px) {
    ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
        font-size: 16px;
      }
      ::-moz-placeholder { /* Firefox 19+ */
        font-size: 16px;
      }
      .datetime-md .datetime-placeholder{
        font-size: 16px;
      }
     .datetime-ios .datetime-placeholder{
        font-size: 15px;
        padding-top:-2px;
     }
     .datetime-ios {
        // padding: 0px 0px 0 0px !important;
        margin-top:-2px;
        font-size: 16px;
    }
  }

//   .segment-md .segment-button{
//       font-size:1.8rem !important;
//   }

  .text-input-md{
    font-size:1.8rem !important;
  }

.ion-md-arrow-back{
    margin-left:14px;
}
.ion-md-close{
    margin-left:14px;
}

//Set ActionSheet Overflow to Auto
.action-sheet-group 
{
    overflow: auto !important;
}

 .btn_addProduct{
     font-weight: normal !important;
 }
 .btn_addProduct .item-inner
 {
    margin-left: 0 !important;
    border-bottom: none !important;
 }
 .text-nodata{
     margin-top: 4px;
 }

 .btn-nodata{
    margin-top: 8px;
    font-size: 17px;
    font-weight: 400 !important;
 }



 .action-sheet-ios .action-sheet-button {
    text-align: left !important;
    text-align: start !important;
    padding-left:1px !important;
    margin-left:2px !important;
    //position: relative !important;
    overflow: hidden !important;
    min-height: 4.8rem;
    font-size: 1.6rem;
    color:#222 ;
    background: transparent;
   // padding: 0 16px !important;
    width : 100% !important;
 }

 .action-sheet-ios {
    text-align: left !important;
  }
  
  .action-sheet-ios .action-sheet-wrapper {
    margin:0px !important;
  }
  
  .action-sheet-ios .action-sheet-container {
    padding: 0px !important;
  }
  
  .action-sheet-ios .action-sheet-group {
    border-radius: 13px;
    margin-bottom: 0px;
    background: #f9f9f9;
    -webkit-overflow-scrolling: touch;
    mask-image: -webkit-radial-gradient(circle, #fff, #000);
  }
  
  .action-sheet-ios .action-sheet-group:first-child {
    margin-top:0px;
  }
  
  .action-sheet-ios .action-sheet-group:last-child {
    margin-bottom: 0px;
  }
  .action-sheet-ios .action-sheet-title {
    padding: 1.5rem;
    text-align: left;
    border-radius: 0;
    border-bottom: 0.55px solid #d6d6da;
    font-size: 1.3rem;
    font-weight: 400;
    color: #8f8f8f;
  }

  

   .p-err
  {
    color: red;
    padding-left: 15px;
    padding-top: 2px;
    padding-bottom: 2px;
    align-content: left !important;
  }
  .pErr-logSign
  {
    color: red;
    padding-top: 2px;
    padding-bottom: 2px;
    align-content: left !important;
  }

  .select-md{
    padding: 8px 8px 10px 0px !important;
  }

  .select-text {
    overflow: hidden;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    min-width: 16px !important;
    width: 16px !important;
    font-size: 1.8rem !important;
    text-overflow: ellipsis !important;
    white-space: nowrap !important;
  }

.outer_title{
    color:white;
    margin-left:10px;
}

.platform-ios .outer_title{	
    color:black;
    margin-left:10px;
}

.menu_list{
    margin-top:10px !important;
}

.select-ios .select-icon{
   right:10px !important;
   position:absolute;
  
}


.platform-ios .label_login{
    margin:13px 8px 5px 0px !important;
}

.platform-ios .input_login
{
    background-color: white;
    padding: 6px !important;
   ::placeholder { color: black; font-family:'Roboto', sans-serif;font-weight:100;font-size: 20px; }//64B6F7 C1E2FC
}

.grid{
    padding: 0 !important;
}
.list-header-md
{
    margin-bottom: 0 !important;
}
.platform-ios .custreg_ios{
    padding: 6px !important;
}


.select-ios {
width : 100% !important;
max-width: 100% !important;
}

.btn-width{
    width: 20% !important;
}


.list-header-ios {
    padding-left: 16px;
    padding-left: 16px;
    position: relative;
    border-bottom: 0.55px solid #c8c7cc;
    font-size: 1.2rem;
    font-weight: 500;
    letter-spacing: 0.1rem;
    text-transform: none;
    color: #333;
    background: transparent;
  }

.platform-ios .img-sidebar-profile
{
    margin-top: 8px !important
}

.platform-ios .img_ios{
    padding-top:5px;
}
.imgz{
    margin-left:-0.49em;
}
.profileHead{
    margin-top: 0.5em;
    color: white;
}


  .toolbar-ios {
    padding-top: 5px !important;
  }


.upper_list{
    border-bottom: 0.55px solid #d6d6da;
    margin-bottom:2px;
}
.footer_icons{
    width: 8px !important;   
}
.footer_icons:first-child
{
    width: 0.95em !important;
    font-size: 2em !important;
    margin-left: -6px !important;
}

.myclass_search{
    margin-top:0px;
    width:100% !important;
    max-width: 100% !important;
    font-size: 1.2rem !important;
    .select-searchable-value
    {
      padding-left: 0px !important;
    }
    //padding: 13px 8px !important;
  }


  .platform-ios .cust_title{
      padding-left:12px !important;
  }

  .platform-ios  .select-searchable-icon {
    position: absolute;
    width: 12px !important;
    right:9.5px;
//padding-right:3px;
}
.platform-ios .trans_ios{
    padding-top: 15px !important;
}

.scroll-content{
    overflow-y:auto;
}
.button-lr-header{
    color:black !important;
    background-color: white !important;
    box-shadow:none !important
}

.my-md-class
{
  padding-left: 0px !important;
  padding-right: 0;
  position: relative;
  font-size: 15px;
  font-weight: normal;
  text-transform: none;
  color: #000;
  background-color: #fff;
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-transition: background-color 300ms cubic-bezier(0.4, 0, 0.2, 1);
  transition: background-color 300ms cubic-bezier(0.4, 0, 0.2, 1);
  .label-md
  {
      margin: 13px 0px 13px 0px !important;
  }
  .text-input-md {
        margin: 13px 0px !important;
        padding: 0 !important;
        width: calc(100% - 8px - 8px) !important;
      }
}
.platform-ios .select-margin-left{
    margin-left: 0px !important;
   }

.button-md {
text-transform: none !important;
}
.alert-md .alert-button {
    text-transform: none !important;
}

.wid-redeem-btn-50 {
    width:50% !important;
    margin-right: 3% !important;
}

.wid-redeem-div {
    width:100% !important;
}



@media (min-width: map-get($grid-breakpoints, xs)){
    .l-logo-block{
        display: none;
    }
    // .path1, .path2{
    //     display: block;
    // }
  }

@media (min-width: map-get($grid-breakpoints, lg)) {
    // @import url('https://fonts.googleapis.com/css?family=Poppins:100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i&display=swap&subset=devanagari,latin-ext');
    .lg-login-wrap{
        background-color: #1889e6 !important;
    }
    .pro-lg-hidden{
        display: none !important;
    }
    .profile_button{
        width:45%;
    }
    
    .login-box{
        width: 460px;
        margin: 0 auto;
        background: #fff;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        padding: 30px;
        border-radius: 10px;
        
    }
    // .login-box{
    //     margin: 0 22px;
    //     background: #fff;
    //     position: absolute;
    //    // padding: 25px;
    //     border-radius: 10px;
    //     color: black !important;
    //     font-family: "Roboto", sans-serif !important;
    //     left: 50%;
    //     top: 50%;
    //     transform: translate(-50%, -50%);
    //     width: 90%;
    // }
    .img_login{
        margin-top: 0px;
    }
    .l-modal-heading{
        font-size: 21px !important;
        font-weight: 500 !important;
        text-transform: uppercase !important;
        color: #000000 !important;
    }
    .text-input-md{
        font-size: 16px !important;
    }
    .cus-select.myclass_search,
    .mylogin_search .select-searchable-value,
    .cus-select.select-text{
        font-size: 16px !important;
    }
    .button-large-md{
        height: 44px;
        font-weight: 600 !important;
        font-size: 17px;
    }
 
    .credit-wrap{
        width: 100%;
      }
      .credit-wrap p{
        padding: 12px 0px;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        background-color: #0a7cd5;
        color: #ffffff;
        font-size: 18px;
      }
      .list-inn{
        background: #ffffff;
        height:80vh;
        overflow-y: auto;
      }
      /* width */
      .list-inn::-webkit-scrollbar {
            width: 5px;
        }
        
        /* Track */
        .list-inn::-webkit-scrollbar-track {
            background: #bebebe; 
        }
        
        /* Handle */
        .list-inn::-webkit-scrollbar-thumb {
            background: #0a7cd5; 
        }
    .transaction-summery{
        padding: 0px 30px;
    }
    .divider-title{
        margin: 20px 0px;
        background-color: transparent;
    }
    .cus-d-title{
        margin: 20px 0px 0px;
        padding: 0px;
        font-size: 18px;
        color: #2196f3;
        background-color: transparent;
        border-bottom: 0px;
    }
    .summery-grid .col .item-md{
        padding-left: 0px;
        margin: 0px 0px 10px;
    }
    .summery-grid .col{
        padding: 0px;
    }
    .summery-info .text-input-md{
        color: #000;
        font-size: 16px !important;
        opacity: 1 !important;
        margin-top: 0px;
    }
    .summery-info .label-md{
        margin-top: 0px;
    }
    .summery-grid{
        background: #fff;
        box-shadow: 0 0 5px #b3b3b3;
        padding: 20px 20px 5px !important;
        border-radius: 10px;
    }
    .summery-info.item-md.item-block .item-inner{
        border-bottom: 0px;
    }
    .transaction-summery{
        width: 70%;
        margin: 0 auto;
    }
    .split-pane-md.split-pane-visible > .split-pane-side{
        min-width: 220px;
        max-width: 10%;
    }
    .tranfer-list-wrap{
        padding: 0 15px;
        width: 100%;
    }
    .l-logo-block{
        display: block;
    }
    .path1, .profile-box h2, .img-sidebar-profile{
        display: none;
    }
    .lg-profile-block ion-label{
        display: flex;
        align-items: center;
    }
    .pro-cir{
        display: flex;
        align-items: center;
    }
    .pro-cir span{
        font-size: 50px;
    }
    .data-box{
        background-color: #ffffff;
    }
    .l-logo-block{
        width: 60px;
        margin: 0 auto;
        display: block;
    }
    .white-header.toolbar-md-primary .toolbar-background-md{
        border-bottom: 1px solid #e8e8e8;
        background-color: #ffffff;
    }
    .white-header{
        min-height: 72px;
        margin-bottom: 15px;
    }
    .white-header.toolbar-md-primary .bar-button-clear-md, 
    .white-header.toolbar-md-primary .bar-button-default-md, 
    .white-header.toolbar-md-primary .bar-button-outline-md, 
    .white-header.toolbar-md-primary .toolbar-title-md{
        color: #2196f3;
    }
    .white-header .l-toggle-btn{
        display: none;
    }
    .content-md{
        background-color: #f4f4f4;
    }
    .scroll-content{
        background-color: #f4f4f4;
    }
    .profile-box .label-md{
        margin: 0px;
    }
    .profile-box .toolbar-md-primary .toolbar-background-md{
        background-color: #ffffff;
        min-height: 64px;
        border-bottom: 1px solid #e8e8e8;
    }
    .header-md::after, 
    .tabs-md[tabsPlacement="top"] > .tabbar::after, 
    .footer-md::before, 
    .tabs-md[tabsPlacement="bottom"] > .tabbar::before{
        background-image: none;
    }
    .l-sidebar .scroll-content{
        background-color: #ffffff;
        margin-top: 64px !important;
    }
    .l-blue-tabs.toolbar-md{
        position: relative;
        overflow: hidden;
    }
    .l-blue-tabs{
        min-height: 43px;
        padding: 0px;
    }
    .l-blue-tabs.toolbar-md .toolbar-background{
        background-color: transparent !important;
    }
    .toolbar .segment-md .segment-button,
    .toolbar .segment-md .segment-button.activated, 
    .toolbar .segment-md .segment-button.segment-activated{
        opacity: 1;
        background: #2196F3 !important;
    }

    .l-blue-tabs.toolbar-md .toolbar-content{
        padding: 0 21px 0 15px;
    }
    .segment-md .segment-button.activated, .segment-md .segment-button.segment-activated {
        border-bottom-color: #f5b14e !important;
    }        
    .l-sidebar ion-list button.item-block{
        border-bottom: 1px solid #e8e8e8 !important;
    }
    .upper_list{
        border: 0px;
    }
    .l-sidebar .footer_icons:first-child{
        font-size: 23px !important;
        margin: 0 !important;
        width: 23px !important;
        text-align: center !important;
    }
    .l-sidebar .item > ion-icon, .item-inner > ion-icon{
        font-size: 26px !important;
        width: 23px !important;
        text-align: center !important;
        margin-left: 0px !important;
        margin: 0 !important;
    }
    .l-sidebar .item-md ion-icon[item-start] + .item-inner{
        margin-left: 19px;
        font-size: 14px;
        color: #0a7cd5 !important;
        z-index: 1;
    }
    .icon-md-grey{
        color: #0a7cd5 !important;
    }   
    .l-sidebar .icon-md-primary::before{
        z-index: 1;
        position: relative;
        color: #ffffff;
    }
    .l-sidebar .label-md-primary{
        color: #ffffff;
    }
    .l-sidebar .icon-md-primary::after{
        content: "";
        z-index: 0;
        position: absolute;
        width: 100%;
        height: 100%;
        background: #0a7cd5;
        top:0;
        left: 0;
    }
    .lg-login-wrap .scroll-content{
        background-color: transparent;
    }
    .profile_list{
        display: flex;
        background: #fff;
        padding: 40px 20px; 
    }
    .profile_list .item-block {
        padding: 0px;
    }
    .data-box{
         flex: 1;
    }
    .data-box ion-item.item.item-block{
        width: 29.33%;
        float: left;
        margin: 0 2% 20px;
        height: 70px;
    }
    .data-box ion-item.item.item-block.profile-buttons{
        width: 100%;
        float: none;
        height: auto;
    }
    .profile-block ion-item{
        display: block !important;
        text-align: center;
        min-width: 200px;
    }
    .profile-block ion-avatar div img{
        border-radius: 12px;
        overflow: hidden;
        width: 130px;
        height: 130px;
        margin: 0 auto;
    }
    .profile-block .item-block .item-inner{
        padding-left: 0px;
        border-bottom: 0px;
    }
    .data-box .item-block .item-inner{
        border-bottom: 1px solid #dedede;
        padding-right: 0px !important;
    }
    .profile_button{
        width: 100%;    
        min-width: 200px;
        height: 45px;
        margin-top: 40px;
    }
    .data-box .item-block.profile-buttons{
        min-width: 200px;
    }
    .data-box .item-block.profile-buttons .item-inner{
        border-bottom: 0px;
    }
    .data-box  .profile-buttons .input-wrapper ion-label{
        display: flex;
        justify-content: center;
    }
    .item-md.item-input.ng-valid.item-input-has-value:not(.input-has-focus):not(.item-input-has-focus) .item-inner, .item-md.item-input.ng-valid.input-has-value:not(.input-has-focus):not(.item-input-has-focus) .item-inner {
        border-bottom-color: #0a7cd5;
        box-shadow: #0a7cd5 0px -1px 0px 0px inset;
    }
    .select-text{
        font-size: 17px !important;
    }
    .profile-block h2{
        font-size: 18px;
        font-weight: 500 !important;
        color: #0a7cd5;
    }
    // .tranfer-summary{
    //     padding: 20px !important;
    //     background-color: #ffffff;
    // }
    .tranfer-summary ion-item-divider.item-divider{
        background: transparent;
        border: 0px;
        padding: 0;
        font-size: 18px;
        font-weight: 500;   
    }
    .tranfer-summary ion-item-divider.item-divider .label{
        margin: 0px 0px 10px;
    }
    .tranfer-summary .item-md,
    .tranfer-summary .item-ios{
        padding-left: 0px;
    }
    .s-t-block{
        background: #ffffff;
        box-shadow: 0 0 5px #c1c1c1;
        border-radius: 10px;
        padding: 0 15px 20px;
        margin-bottom: 20px;
    }
    .item-label-stacked .text-input-md, .item-label-floating .text-input-md,
    .item-label-stacked .text-input-ios, .item-label-floating .text-input-ios{
        opacity: 1;
    }
    .null-data-block{
       background-color: #ffffff;
       width: 100%;
       padding: 20px 20px 40px;
       text-align: center;
    }
    .white-btn{
        width: 200px;
        min-width: 200px;
        height: 45px;
    }
    .blue-btn{
        width: 200px;
        min-width: 200px;
        height: 45px;
    }
    .xs-mr-b30{
        margin-bottom: 30px;
    }
    .toolbar .segment-md,.toolbar .segment-ios {
        // border: 1px solid #2196f3;
        border-radius: 7px;
        overflow: hidden;
    }

    // new list design
    .new-tranfer-list{
        display: block;
        // overflow: hidden;
        padding: 0 20px;
        // height: 500px;
        // overflow: auto;
        
    }
    .tranfer-list-wrap{
        overflow-y: auto;
    }
    .h-l-tabs{
        display: none;
    }
    .n-t-list-wrap .scroll-content{
        display: flex;
        background-color: #f4f4f4;
        // padding: 0px 15px;
    }
    .n-t-list-wrap .tranfer-list-wrap{
        background-color: #ffffff;  
        margin: 0px 15px 20px;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        padding: 0px;
    }
    .n-t-list-wrap .tranfer-list-wrap .item-md{
        padding-left: 0px;
    }
    .send-col{
        position: relative;
        padding: 0px;
    }
    .tranfer-list-wrap.send-col::before,
    .tranfer-list-wrap.recei-col::before,
    .tranfer-list-wrap.credit-col::before,
    .tranfer-list-wrap.debit-col::before{
        content: "Sent";
        display: block;
        width: 100%;
        padding: 12px 0px;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        background-color: #0a7cd5;
        position: sticky;
        top: 0;
        z-index: 999;
        font-size: 18px;
        color: #ffffff;
        text-align: center;
    }
    .tranfer-list-wrap.recei-col::before{
        content: "Received";
    }
    .tranfer-list-wrap.credit-col::before{
        content: "Credit";
    }
    .tranfer-list-wrap.debit-col::before{
        content: "Debit";
    }
    .new-tranfer-list button.item-block ion-icon{
        display: none;
    }
    .new-tranfer-list button.item-block .item-inner{
        margin: 0px;
        padding: 0px;
    }
    .new-tranfer-list button.item-block .input-wrapper .label .row{
        flex-direction: column;
    }
    .new-tranfer-list button.item-block .input-wrapper .item-note .item-note{
        color: rebeccapurple !important;
    }
    .new-tranfer-list button.item-block .input-wrapper .item-note.item-second{
        color: red !important;
    }
    .new-tranfer-list button.item-block .input-wrapper .item-note.item-second:nth-child(2){
        color: blue !important;
    }
  }

.segment-md .segment-button.activated, .segment-md .segment-button.segment-activated {
    border-bottom-color: #fdd016 !important;
    border-bottom-style: solid !important;
    border-bottom-width: 3px !important;
  }

  
  .menu_list .footer_icons.icon-md-primary::after,
  .menu_list .icon-md-primary::after,
  .menu_list .footer_icons.icon-ios-primary::after,
  .menu_list .icon-ios-primary::after{
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background: #f5b14e;
    left: 0;
    top: 0;
  }
  .menu_list .label-md-primary, .menu_list .icon-md-primary::before,
  .menu_list .label-ios-primary, .menu_list .icon-ios-primary::before{
    position: relative;
    z-index: 9;
    color: #000000 !important;
    font-weight: 600; 
  }

  .content-bgcolor{
    background-color: #DCDCDC !important;
  }
.add-info{
    background-color: white !important;
}

.md .alert-input {
    border-bottom: 2px solid blue;
    margin-bottom: 3px;
  }
  
  .md .alert-input:focus {
    border-bottom: 2px solid blue;
    margin-bottom: 3px;
  }
  
  .ios .alert-input {
    margin-bottom: 2px;
  }
  
  .validation-errors {
    padding-top: 5px;
    text-align: left;
  }
  
  .error-message {
    color: red;
    font-size: 12px;
  }
//   .login-box{
//     margin: 0 22px;
//     background: #fff;
//     position: absolute;
//    // padding: 25px;
//     border-radius: 10px;
//     color: black !important;
//     font-family: "Roboto", sans-serif !important;
//     left: 50%;
//     top: 50%;
//     transform: translate(-50%, -50%);
//     width: 90%;
// }